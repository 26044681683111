import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import starIcon from "../../assets/images/star-icon.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		file(relativePath: { eq: "shape/vector-shape11.png" }) {
			childImageSharp {
				gatsbyImageData(
					width: 742
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const D = () => {
	const { file } = useStaticQuery(query);
	const vectorShape = getImage(file);
	const { t } = useTranslation();

	return (
		<section className="industries-serve-area bg-f1f8fb pt-100 pb-70">
			<div className="container">
				<div className="section-title">
					<span className="sub-title">
						<img src={starIcon} alt="banner" />
						<p>{t("home.Dp1")}</p>
					</span>
					<h4>{t("home.Dh4")}</h4>
					<p>{t("home.Dp2")}</p>
				</div>

				<div className="row">
					<div className="col-lg-3 col-sm-6 col-md-6">
						<div className="single-industries-serve-box">
							<div className="icon">
								<i className="flaticon-team"></i>
							</div>
							{t("home.Di1")}
							<Link to="/services" className="link-btn"></Link>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6 col-md-6">
						<div className="single-industries-serve-box">
							<div className="icon">
								<i className="flaticon-left-quote"></i>
							</div>
							{t("home.Di2")}
							<Link to="/services" className="link-btn"></Link>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6 col-md-6">
						<div className="single-industries-serve-box">
							<div className="icon">
								<i className="flaticon-open-padlock"></i>
							</div>
							{t("home.Di3")}
							<Link to="/services" className="link-btn"></Link>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6 col-md-6">
						<div className="single-industries-serve-box">
							<div className="icon">
								<i className="flaticon-segmentation"></i>
							</div>
							{t("home.Di4")}
							<Link to="/services" className="link-btn"></Link>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6 col-md-6">
						<div className="single-industries-serve-box">
							<div className="icon">
								<i className="flaticon-view"></i>
							</div>
							{t("home.Di5")}
							<Link to="/services" className="link-btn"></Link>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6 col-md-6">
						<div className="single-industries-serve-box">
							<div className="icon">
								<i className="flaticon-price-tag"></i>
							</div>
							{t("home.Di6")}
							<Link to="/services" className="link-btn"></Link>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6 col-md-6">
						<div className="single-industries-serve-box">
							<div className="icon">
								<i className="flaticon-teacher"></i>
							</div>
							{t("home.Di7")}
							<Link to="/services" className="link-btn"></Link>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6 col-md-6">
						<div className="single-industries-serve-box">
							<div className="icon">
								<i className="flaticon-idea"></i>
							</div>
							{t("home.Di8")}
							<Link to="/services" className="link-btn"></Link>
						</div>
					</div>
				</div>
			</div>
			<div className="vector-shape11">
				<GatsbyImage image={vectorShape} alt="Shape" />
			</div>
		</section>
	);
};

export default D;
