import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import starIcon from "../../assets/images/star-icon.png";
import client1 from "../../assets/images/testimonials/client1.jpg";
import shape from "../../assets/images/shape/shape1.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const H = () => {
	const { t } = useTranslation();

	return (
		<div className="testimonials-area pt-100 bg-f1f8fb">
			<div className="container">
				<div className="section-title">
					<span className="sub-title">
						<img src={starIcon} alt="testimonial" />
						{t("home.Hp")}
					</span>
					<h2>{t("home.Hh2")}</h2>
				</div>

				<div className="single-testimonials-item">
					<p>{t("home.Hp1")}</p>

					<div className="client-info">
						<div className="d-flex justify-content-center align-items-center">
							<img
								src={client1}
								width="51"
								height="51"
								alt="testimonial"
							/>
							<div className="title">
								<h3>Emmanuel ROUX</h3>
								<span>{t("home.Hp2")}</span>
							</div>
						</div>
					</div>
				</div>

				<div className="testimonials-view-btn text-center">
					<Link
						to="/employee-profile-branding-system"
						className="default-btn"
					>
						<i className="flaticon-right"></i>
						<h3>{t("home.Hh3")}</h3>
						<span></span>
					</Link>
				</div>
			</div>

			<div className="shape-img1">
				<img src={shape} alt="testimonial" />
			</div>
		</div>
	);
};

export default H;
