import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactWOW from "react-wow";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		file(relativePath: { eq: "projects/benchmark.jpg" }) {
			childImageSharp {
				gatsbyImageData(
					width: 821
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const G = () => {
	const { file } = useStaticQuery(query);
	const benchmark = getImage(file);
	const { t } = useTranslation();

	return (
		<div className="digital-agency-banner">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12">
						<div className="single-industries-serve-box">
							{t("home.Gp1")}
							<Link
								to="/service-details-Cbench"
								className="link-btn">
							</Link>
						</div>

						<div className="banner-wrapper-content">
							<ReactWOW delay=".1s" animation="fadeInLeft">
								<h3>{t("home.Gh1")}</h3>
							</ReactWOW>

							<ReactWOW delay=".1s" animation="fadeInLeft">
								<p>{t("home.Gp2")}</p>
							</ReactWOW>

							<ReactWOW delay=".1s" animation="fadeInRight">
								<div className="btn-box">
									<Link to="/contact" className="default-btn">
										<i className="flaticon-right"></i>
										{t("NousContacter")}
										<span></span>
									</Link>

										<Link
											to="https://admin.crowlr.com/register"
											className="default-btn"
										>
											<i className="flaticon-tick"></i>
											{t("Créeruncomptegratuit")}
											<span></span>
										</Link>
								</div>
							</ReactWOW>
						</div>
					</div>

					<div className="col-lg-6 col-md-12">
						<ReactWOW delay=".1s" animation="fadeInUp">
							<div className="banner-wrapper-image">
								<GatsbyImage image={benchmark} alt="service" />
							</div>
						</ReactWOW>
					</div>

				</div>
			</div>
		</div>
	);
};

export default G;
