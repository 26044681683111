import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		file(relativePath: { eq: "seo-analysis.png" }) {
			childImageSharp {
				gatsbyImageData(
					width: 650
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const J = () => {
	const { file } = useStaticQuery(query);
	const seoAnalysis = getImage(file);
	const { t } = useTranslation();

	return (
		<div className="seo-analysis-section ptb-100">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="analysis-img">

							<form>
							</form>

							<div className="btn-box">
								<Link
									to="https://admin.crowlr.com/register"
									className="default-btn"
								>
									<i className="flaticon-right"></i>
									{t("Créeruncomptegratuit")}
									<span></span>
								</Link>
							</div>

						</div>
					</div>

					<div className="col-lg-6">
						<div className="analysis-form">
							<h2>{t("home.Jh2")}</h2>
							<h4>{t("home.Jh4")}</h4>
							<p>{t("home.Jp1")}</p>

						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default J;
