import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactWOW from "react-wow";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		file(relativePath: { eq: "events/softrh.png" }) {
			childImageSharp {
				gatsbyImageData(
					width: 1199
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const C = () => {
	const { file } = useStaticQuery(query);
	const softrh = getImage(file);
	const { t } = useTranslation();

	return (
		<section className="services-area pt-100 pb-70 bg-f3f8fb">
			<div className="section-title">
				<h2>{t("home.Ch2")}</h2>
				<p>{t("home.Cp1")}</p>
			</div>

			<ReactWOW delay=".1s" animation="fadeInUp">
				<div className="main-banner-image">
				</div>
			</ReactWOW>

			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-6 col-sm-6">
						<div className="single-solutions-box">
							<div className="icon">
								<i className="flaticon-money"></i>
							</div>
							<h3>
								<ref to="/services">
									{t("home.Ch31")}
								</ref>
							</h3>
							<p>{t("home.Cp2")}</p>
							<Link className="view-details-btn" to="/services">
								{t("EnSavoirPlus")}
							</Link>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6">
						<div className="single-solutions-box">
							<div className="icon">
								<i className="flaticon-agendas"></i>
							</div>
							<h3>
								<ref to="/hr-recruitment-solution">
									{t("home.Ch32")}
								</ref>
							</h3>
							<p>{t("home.Cp3")}</p>
							<Link
								className="view-details-btn"
								to="/hr-recruitment-solution"
							>
								{t("EnSavoirPlus")}
							</Link>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6">
						<div className="single-solutions-box">
							<div className="icon">
								<i className="flaticon-analysis"></i>
							</div>
							<h3>
								<ref to="/candidature-interactive-et-employabilité">
									{t("home.Ch33")}
								</ref>
							</h3>
							<p>{t("home.Cp4")}</p>
							<Link
								className="view-details-btn"
								to="/interactive-job-candidacy-and-employability"
							>
								{t("EnSavoirPlus")}
							</Link>
						</div>
					</div>
				</div>
			</div>

		</section>
	);
};

export default C;
