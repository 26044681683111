import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import starIcon from "../../assets/images/star-icon.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		file(relativePath: { eq: "services/service5.png" }) {
			childImageSharp {
				gatsbyImageData(
					width: 830
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const F = () => {
	const { file } = useStaticQuery(query);
	const service = getImage(file);
	const { t } = useTranslation();

	return (
		<section className="overview-area ptb-100 pt-0">
			<div className="container">
				<div className="overview-box">
					<div className="overview-image">
						<div className="image">
							<GatsbyImage image={service} alt="service" />
						</div>
					</div>

					<div className="overview-content">
						<div className="content">
							<div className="section-title">
								<span className="sub-title">
									<img src={starIcon} alt="banner" />
									<p>{t("home.Fp")}</p>
								</span>
								<h4>{t("home.Fh4")}</h4>
							</div>

							<div className="how-its-work-content">
								<div className="inner-box">
									<div className="single-item">
										<div className="count-box">1</div>
										<h3>{t("home.Fh31")}</h3>
									</div>
									<div className="single-item">
										<div className="count-box">2</div>
										<h3>{t("home.Fh32")}</h3>
									</div>
									<div className="single-item">
										<div className="count-box">3</div>
										<h3>{t("home.Fh33")}</h3>
									</div>
								</div>
							</div>

							<div className="features-text"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default F;
