import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		shape: file(
			relativePath: { eq: "our-mission/our-mission-shape1.png" }
		) {
			childImageSharp {
				gatsbyImageData(
					width: 919
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
		mission: file(relativePath: { eq: "our-mission/our-mission2.png" }) {
			childImageSharp {
				gatsbyImageData(
					width: 778
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const E = () => {
	const { shape, mission } = useStaticQuery(query);
	const vectorShape = getImage(shape);
	const vectorMission = getImage(mission);
	const { t } = useTranslation();

	return (
		<section className="our-mission-area ptb-100">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12">
						<div className="our-mission-content">
							<div className="content">
								<span className="sub-comment">
									<div className="events-details-header">
										<span>
											<h3>{t("home.Ep1")}</h3>

										</span>
									</div>
								</span>
								<h4>{t("home.Eh4")}</h4>
								<p>{t("home.Ep2")}</p>
								<p>{t("home.Ep3")}</p>
							</div>
						</div>
					</div>

					<div className="col-lg-6 col-md-12">
						<div className="our-mission-image">
							<GatsbyImage image={vectorMission} alt="Shape" />
							<div className="shape">
								<GatsbyImage image={vectorShape} alt="Shape" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default E;
