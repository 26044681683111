import React from "react";
import ReactWOW from "react-wow";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import StarIcon from "../../assets/images/star-icon.png";
import shape1 from "../../assets/images/shape/shape1.svg";

import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		file(relativePath: { eq: "map.png" }) {
			childImageSharp {
				gatsbyImageData(
					width: 750
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const A = () => {
	const { file } = useStaticQuery(query);
	const banner = getImage(file);
	const { t } = useTranslation();
	return (



		<section className="services-area pt-100 pb-70 bg-f3f8fb">

			<p>{}</p>

			<div className="section-title">
				<p>{}</p>
				<span className="sub-title">
										<p>
											<img src={StarIcon} alt="banner" />
											{}
										</p>
									</span>
			</div>


			<div className="section-title">
			<span className="sub-title">
										<p>
											{t("home.APara")}
										</p>
			</span>
			</div>

			<div className="row">
				<div className="col-lg-8 col-md-6 col-sm-6"></div>



					<ReactWOW delay=".1s" animation="fadeInUp">
						<div className="section-title">
							<ReactWOW delay=".1s" animation="fadeInLeft">



						<div className="section-title">
									<span>
										<h1>{t("home.ATitle")}</h1>
									</span>
									<span className="sub-title">
										<p>
											<img src={shape1} alt="banner" />
										</p>
									</span>
									<ReactWOW
										delay=".1s"
										animation="fadeInRight"
									>
										<p>
											One Tool &trade; {t("home.ADesc")}
										</p>
										<div className="btn-box">
											<Link
												to="https://admin.crowlr.com/register"
												className="default-btn"
											>
												<i className="flaticon-right"></i>
												{t("Créeruncomptegratuit")}
												<span></span>
											</Link>
										</div>
									</ReactWOW>

									<div className="section-title">
										<h2>{}</h2>
										<p>{}</p>
									</div>

								</div>
							</ReactWOW>

			</div>

		</ReactWOW>


			<div className="col-lg-6 col-md-12">
						<ReactWOW delay=".1s" animation="fadeInUp">
							<div className="main-banner-image">
								<GatsbyImage image={banner} alt="banner" />
							</div>
						</ReactWOW>
				</div>
			</div>

		</section>
	);
};

export default A;
