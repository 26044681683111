import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import starIcon from "../../assets/images/star-icon.png";
import ReactWOW from "react-wow";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		file(relativePath: { eq: "services/service1.png" }) {
			childImageSharp {
				gatsbyImageData(
					width: 810
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const B1ourServices = () => {
	const { file } = useStaticQuery(query);
	const serviceImg = getImage(file);
	const { t } = useTranslation();
	return (
		<React.Fragment>
			{/* Service Left Image Style */}
			<div className="about-area">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12">
							<div className="about-img">
								<GatsbyImage image={serviceImg} alt="service" />
							</div>
						</div>

						<div className="col-lg-6 col-md-12">
							<div className="main-banner-content">
								<ReactWOW delay=".1s" animation="fadeInLeft">
									<div className="section-title">
										<div className="content">
											<span className="sub-title">

												<p>{t("home.OurServices1p")}</p>
											</span>
										</div>
										<span>
											<h2>{t("home.OurServicesh2")}</h2>
										</span>
										<ReactWOW
											delay=".1s"
											animation="fadeInRight"
										>
											<p>{t("home.OurServices2p")}</p>


										</ReactWOW>
									</div>
								</ReactWOW>
							</div>

							<div className="about-content">
								<div className="content">
									<ul className="about-list mb-0">
										<li>
											<i className="flaticon-tick"></i>
											{t("home.OurServices1i")}{" "}
										</li>
										<li>
											<i className="flaticon-tick"></i>
											{t("home.OurServices2i")}{" "}
										</li>
										<li>
											<i className="flaticon-tick"></i>
											{t("home.OurServices3i")}{" "}
										</li>
										<li>
											<i className="flaticon-tick"></i>
											{t("home.OurServices4i")}{" "}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default B1ourServices;
