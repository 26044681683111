import React from 'react';
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import A1banner from '../components/Index/A1banner';
import B1ourServices from '../components/Index/B1ourServices';
import C1indexKeyTakeAway from '../components/Index/C1indexKeyTakeAway';
import D1kpi from '../components/Index/D1kpi';
import E1ourMission from '../components/Index/E1ourMission';
import F1services from '../components/Index/F1services';
import G1mainBanner from '../components/Index/G1mainBanner';
import H1testimonials from '../components/Index/H1testimonials';
import J1compteGratuit from "../components/Index/J1compteGratuit";
import { Helmet } from "react-helmet"

const Home = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />

            <A1banner />
            <B1ourServices />
            <C1indexKeyTakeAway />
            <D1kpi />
            <E1ourMission />
            <F1services />
            <G1mainBanner />
            <H1testimonials />
            <J1compteGratuit />

            <Footer />
        </Layout>
    );
}

class Application extends React.Component {
    render() {
        return (
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>
                        HR Software [HRMS] | AI Based Recruting System | Crowlr
                        Convert the above into French
                    </title>
                    <link rel="canonical" href="http://crowlr.com" />
                </Helmet>
            </div>
        )
    }
}


export default Home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;